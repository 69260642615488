/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';
@import '../node_modules/bootstrap/scss/_mixins.scss';
@import '../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/utilities/api';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700';


@include mat.core();

$primary-palette: (
  50 : #e1eef7,
  100 : #b3d4ea,
  200 : #80b7dc,
  300 : #4d9ace,
  400 : #2785c4,
  500 : #016fb9,
  600 : #0167b2,
  700 : #015caa,
  800 : #0152a2,
  900 : #004093,
  A100 : #bed6ff,
  A200 : #8bb5ff,
  A400 : #5894ff,
  A700 : #3f84ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
);
$accent-palette: (
  50 : #fbfbfb,
  100 : #f5f5f5,
  200 : #efefef,
  300 : #e8e9e9,
  400 : #e3e4e4,
  500 : #dedfdf,
  600 : #dadbdb,
  700 : #d5d7d7,
  800 : #d1d2d2,
  900 : #c8caca,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);
$warn-palette: (
  50 : #ffe0e0,
  100 : #ffb3b3,
  200 : #ff8080,
  300 : #ff4d4d,
  400 : #ff2626,
  500 : #ff0000,
  600 : #ff0000,
  700 : #ff0000,
  800 : #ff0000,
  900 : #ff0000,
  A100 : #ffffff,
  A200 : #fff2f2,
  A400 : #ffbfbf,
  A700 : #ffa6a6,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$primary: mat.define-palette($primary-palette);
$accent: mat.define-palette($accent-palette);
$warn: mat.define-palette($warn-palette);
$typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $body-1: mat.define-typography-level(13px, 18px, 400),
  $body-2: mat.define-typography-level(13px, 20px, 400),
  $caption: mat.define-typography-level(10px, 16px, 400),
);
// $body-1: mat.define-typography-level(8px, 12px, 400, $letter-spacing: 0.03125em),
$pos-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: $typography,
  density: 0,
));
@include mat.all-component-typographies($typography);
@include mat.all-component-themes($pos-theme);

// // $color-config: mat.get-color-config($pos-theme);
// $primary-color: map-get($pos-theme, 'primary');
// $accent-color: map-get($pos-theme, 'accent');
// $warn-color: map-get($pos-theme, 'warn');
// :root {
//   --color-primary: mat.get-color-from-palette($primary-palette, 500);
//   --color-accent: mat-color($accent-color);
//   --color-warn: mat-color($warn-color);
// }
body {
  --primary-color: #016fb9;
  --primary-lighter-color: #b3d4ea;
  --primary-darker-color: #004093;
  // --text-primary-color: #{$dark-primary-text};
  // --text-primary-lighter-color: #{$dark-primary-text};
  // --text-primary-darker-color: #{$dark-primary-text};
  --accent-color: #dedfdf;
  --accent-lighter-color: #efefef;
  --accent-darker-color: #c8caca;
  // --text-accent-color: #{$light-primary-text};
  // --text-accent-lighter-color: #{$dark-primary-text};
  // --text-accent-darker-color: #{$light-primary-text};
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;

  --success-color: #008000;
  // --text-warn-color: #{$light-primary-text};
  // --text-warn-lighter-color: #{$dark-primary-text};
  // --text-warn-darker-color: #{$light-primary-text};
}
html, body { 
  height: 100%; 
  background: #e8e7e7; 
}
body { 
  margin: 0; 
  font-family: Montserrat, "Helvetica Neue", sans-serif; 
}
a{
  color: #fff;
}
// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}
.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}
.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}
.error{
  color: red;
  font-size: 11px;
  font-weight: 600;
}
.print-area{
  display: none;
}
.success{
  color: #ffffff;
  --mdc-snackbar-container-color: var(--success-color);
  --mat-mdc-snack-bar-button-color: #ffffff;
}
.cancel{
  color: #ffffff;
  --mdc-snackbar-container-color: var(--warn-color);
  --mat-mdc-snack-bar-button-color: #ffffff;
}
.non-print{
  display: block !important;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 10px;
}
.mat-mdc-form-field-infix {
  min-height: 39px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mdc-floating-label--float-above{
  top: 30px !important;
}
.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 6px 9px 6px 6px !important;
}
.mat-mdc-select-arrow-wrapper {
  height: 18px !important;
}
.mat-datepicker-toggle > .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px !important;
  height: 40px !important;
  padding: 6px 9px 6px 6px !important;
}
// .mat-mdc-icon-button.mat-mdc-button-base{
//   width: 40px !important;
//   height: 40px !important;
//   padding: 6px 9px 6px 6px !important;
// }
.loading-dialog .mat-mdc-dialog-container{
  --mdc-dialog-container-color: transparent !important;
}
.loading-dialog .mat-mdc-dialog-container .mdc-dialog__surface{
  box-shadow: unset !important;
}
mat-form-field{
  width: 100%;
}
mat-card{
  font-family: Montserrat !important;
  border-radius: 24px !important;
  box-shadow: unset !important;
  padding: 10px;
  border-style: unset !important;
  border-width: unset !important;
  border-color: unset !important;
  position: relative;
  top: -5px;
}
@media print{
  body {
    color:#000000;
  }
  .non-print{
    display: none !important;
  }
  .print-area{
    display: block;
    // min-height: 600px;
    height: 100%;
    min-width: 100%;
    width: 100%;
    overflow: visible !important;
    margin:auto;
  }
}